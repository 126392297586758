.home-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  text-align: center;
  color: #012b46;
  background-image: url("../assert/about-us.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.home-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the whole area */
  z-index: -1; /* Places the video behind the text */
}

.home-content h1 {
  z-index: 1; /* Places the heading above the video */
  margin: 0;
  font-size: 3em;
  letter-spacing: 2px;
  font-weight: 500;
}

.welcome-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff; /* Background color to make the text readable */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a card effect */
  border-radius: 10px;
  text-align: center;
}

.welcome-div h2 {
  margin-bottom: 10px;
  font-size: 2em;
  color: #012b46;
  letter-spacing: 1px;
}

.welcome-div hr {
  width: 100px;
  border: 2px solid #bfa582;
  margin: 10px auto;
}

.welcome-card p {
  margin-top: 20px;
  font-size: 1.5em;
  color: #333;
  line-height: 1.5;
  text-align: justify;
  padding: 0 20px;
}

#choose {
  width: 100%;
  height: auto;
  /* background-color: #012b46; */
  padding: 20px 0;
}

.choose-content {
  background-image: url(../assert/choose-us-2.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size:cover;
  font-size: 1.2em;
  backdrop-filter: blur(10px);
  padding: 5%;
  margin: 2%;
  color: #fff;
}

.choose-content p {
  text-align: justify;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  font-size: 1.2em;
}

#myh2 {
  color: #fff;
}

.choose-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 95%;
  font-size: 1.2em;
  color: #000000;
  margin: 2% auto;
  padding: 20px 10px;
  justify-content: center;
  align-items: center;
}

.c-card {
  text-align: left;
  margin: 10px;
  padding: 20px;
  background-color: #f0f8ff;
  font-weight: 600;
  width: 300px;
  display: grid;
  height: 100%;
  z-index: 1;
}

.c-card p {
  text-align: justify;
}

.c-card i {
  font-size: 3em;
  padding-top: 30px;
  margin-bottom: 6px;
}

.c-card h5 {
  font-size: 1em;
  margin-bottom: -20px;
  font-weight: lighter;
}

.c-card h3 {
  font-weight: 700;
  color: #012b46;
}

.choose-content h2 {
  font-family: "Shadows Into Light";
  font-size: 40px;
  font-weight: 700;
  line-height: 26.4px;
  color: aliceblue;
}

@media screen and (max-width: 650px) {

  .home-content h1{
    font-size: 1.5em;
  }
  .welcome-card {
    width: 90%;
    padding: 15px;
  }

  .welcome-div h2 {
    font-size: 1.5em;
  }

  .welcome-card p {
    font-size: 1.2em;
    padding: 0 10px;
  }

  #choose {
    height: 100%;
    /* background-color: #012b46; */
  }

  .choose-content {
    margin-left: 2%;
  }

  .choose-content p {
    margin-left: 0%;
  }

  #choose img {
    height: 95vh;
  }
}

@media screen and (min-width: 650px) and (max-width: 990px) {
  .welcome-card {
    width: 85%;
    padding: 20px;
  }

  .welcome-div h2 {
    font-size: 1.75em;
  }

  .welcome-card p {
    font-size: 1.5em;
    padding: 0 15px;
  }

  .choose-content {
    margin-left: 5%;
  }
}

/* src/ResponsiveForm.css */
.interact{
    background-color: #012b46;
    color: white;
    padding: 3%;
}

.contact-container{
    padding-top: 50px;
}

.contact-h1{
    color: #012b46;
    font-size: 3em;
}
.interact-h1{
    display: flex;
    justify-content: left;
    padding: 20px;
    font-size: 3em;
    font-weight: 100;
    margin: 0%;
}
.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
    margin: auto;
    padding: 1rem;
    /* border: 1px solid #ccc; */
    border-radius: 0px;
    color: white;
    /* background-image: url(../assert/contact-1.jpg);
    background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover; */
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  
  .form-group label {
    margin-bottom: 0.5rem;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 0.5rem;
    border: none;
    border-bottom: 2px solid;
    background: transparent;
    border-color: var(--corvid-border-color, rgba(var(--brd,227,227,227), 1));
    border-width: var(--corvid-border-width, var(--brw, 1px));
    box-sizing: border-box;
    outline: none;
    color: white;
  }
  
  button {
    padding: 0.75rem;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    background-color: transparent;
    color: white;
  }
  
  .form-group input:hover,
  .form-group textarea:hover{
    border: 2px solid;
  }


  button:hover {
    background-color: #012b46;
  }
  
  .location {
    margin: 4% 2%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 1.2em;
    width: auto;
    height: auto;
  }
  .location-details{
    display:flex;
    flex-direction: column;
    text-align: left;
    align-items: left;
    width: 45%;
    padding: 2%;
  }
  .details{
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: left;
  }
  .details i{
    padding: 15px 10px;
  }
  .details a{
    text-decoration: none;
    color: black;
  }

  .location-map {
    width: 50%;
  }

  .location-map iframe {
    height: 80%;
    width: 100%;
  }
  @media (max-width: 600px) {
    .contact-container{
    padding-top: 135px;
    }
    .form {
      padding: 1rem;
    }
  .location{
    flex-direction: column;
  }
  .location-details{
    width: 100%;
  }
  .location-map {
    width: 100%;
    height: 100%;
  }
    button {
      padding: .5rem;
    }
  }
  @media screen and (min-width: 650px) and (max-width: 990px) {
    .contact-container{
      padding-top: 135px;
      }
  }
.NavbarItems {
  display: inline-flex;
  justify-content: space-between;
  align-items:center;
  padding: 0 30px;
  width: 96%;
  height: 120px;
  position:fixed;
  left: 50%;
  transform: translate(-50%);
  z-index: 9999;
}
.NavbarItems-bg{
  /* background: linear-gradient(to bottom,  rgba(0, 0, 0, 0.7) 0%,rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0)); */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: 20%;
}
.NavbarItems-bg2{
  background-color: #012b46;
  transition: background-color 1s;
}

.navtitle{
display: flex;
flex-direction: row;
}

.nav-div img{
  border-radius: 50px;
  margin: 10px;
}
.nav-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: 20px;
  letter-spacing: 3px;
}
.nav-div h1{
  color: hsl(0, 0%, 100%);
  font-weight: 500;
  font-style: normal;
}
.nav-title{
    text-align: center;
    cursor: pointer;
    font-size: 2.5em;
}
.navbar-logo {
  font-size: 2rem;
  cursor: pointer;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
}

.nav-links {
  text-decoration: none;
  color: hsl(0, 0%, 100%);
  font-weight: 600;
  margin: 0.7rem 1rem;
  padding: 0.4rem;
  white-space: nowrap;
  font-size: 1.5em;
  font-weight: lighter;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.nav-links::after {
  color: #bfa582;
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #bfa582;
  transform-origin: center;
  transition: transform 0.35s ease-out;
}
.nav-links:hover::after{
  transform: scaleX(1);
  transform-origin: center;
}
.nav-links:hover{
  color: #bfa582;
}

button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

button:hover {
  background-color: #151516;
  color: #fff;
}

.menu-icons {
  display: none;
}

@media screen and (max-width: 950px) {
  .NavbarItems{
    display: inline-flex;
    flex-direction: row-reverse;
    z-index: 99;
  }
  .nav-div{
    width: 90%;
    justify-content: center;
  }
  .nav-title{
    font-size: 30px;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;
    background-color: #012b46;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 120px;
    left: -100%;
    opacity: 0; /* Adjusted padding */
    margin: 0;
    transition: 0.3s ease-in-out;
    border-radius: 13px;
  }

  .nav-menu.active {
    width: 100%;
    left: 0%;
    opacity: 1;
    z-index: -1;
  }
  .nav-links {
    font-size: 1.2rem;
  }
  .nav-links:hover {
    transition: none; /* Adjusted property name */
  }

  .menu-icons {
    display: inline;
    cursor: pointer;
  }
  .menu-icons i {
    font-size: 2em;
    color: #fff;
    font-weight: 700;
  
}
}

@media screen and (max-width: 650px){
  .nav-title{
    font-size: 30px;
  }

}
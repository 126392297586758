.myh1{
  color: #012b46;
  padding-top: 40px;
  font-size: 3em;
}
/* .mydiv{
background-color: rgb(255, 0, 0);
} */
.service-p {
font-size: 1.2em;
padding: 10px 200px;
font-weight: 600;
}
.mydiv hr{
width: 350px;
  position: relative;
  text-align: center;
  justify-content: center;
  border: 2px solid #bfa582;
  color: #bfa582;
}

.s-card{
text-align: left;
margin: 10px;
padding: 20px;
background-color: rgba(255, 255, 255, 0.8);
font-weight: 600;
width: 300px;
display: grid;
height: 250px;
z-index: 1;
}
.s-card p{
text-align: justify;
}
.s-card img {
width: 150px;
height: 150px;
align-items: center;
justify-content: center;
place-self: center;
}
.s-card h5{
font-size: 1em;
margin-bottom: -20px;
font-weight: lighter;
}
.s-card h3{
font-weight: 700;
color: #012b46;
}
.service-card{
display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 95%;
  font-size: 1.2em;
  color: #000000;
  margin: 2%;
  /* background-image:url(../assert/service.jpg);
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      background-size: cover; */
  padding: 20px 10px;
  background-color: #012b46;
}

.icon {
padding: 40px;
-webkit-animation: mover 0.5s infinite  alternate;
animation: mover 0.3s infinite  alternate;
}
@-webkit-keyframes mover {
0% { transform: translateY(0); }
100% { transform: translateY(-20px); }
}
@keyframes mover {
0% { transform: translateY(0); }
100% { transform: translateY(-20px); }
}

.ad-service-1,
.ad-service-3,
.ad-service-5
 {
  display: flex;
  flex-direction: row;
  /* background-color: #012b46; */
  width: 90%;
  margin: 5% auto;
  padding: 20px;
  height:fit-content;
  font-size: 1.2em;
}

.ad-service-2,
.ad-service-4,
.ad-service-6{
  display: flex;
  flex-direction: row-reverse;
  width: 90%;
  margin: 5% auto;
  padding: 20px;
  height:fit-content;
  font-size: 1.2em;
}
.ad-img-1,
.ad-img-2,
.ad-img-3,
.ad-img-4,
.ad-img-5,
.ad-img-6 {
  width: 50%;
  height: 50vh;
}

.ad-img-1 img{
  width: 100%;
  height: 100%;
}
.ad-img-2 img{
  width: 100%;
  height: 100%;
}
.ad-img-3 img{
  width: 100%;
  height: 100%;
}
.ad-img-4 img{
  width: 100%;
  height: 100%;
}
.ad-img-5 img{
  width: 100%;
  height: 100%;
}
.ad-img-6 img{
  width: 100%;
  height: 100%;
}

.ad-content-1,
.ad-content-2,
.ad-content-3,
.ad-content-4,
.ad-content-5,
.ad-content-6 {
  width: 50%;
  padding: 20px;
  background-color: #f0f8ff;
  text-align: justify;
  font-weight: 600;
}
.ad-content-1,
.ad-content-2,
.ad-content-3,
.ad-content-4,
.ad-content-5,
.ad-content-6  p{
  font-size: 1.3rem;
}

.slide p,h2{
  font-size: 1.8em;
}

.slideshow {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-bottom: 20px;
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  opacity: 1;
}

.nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.nav.prev {
  left: 10px;
}

.nav.next {
  right: 10px;
}

@media screen and (min-width: 650px) and (max-width: 990px) {
  .icon {
    display: none;
  }
  .mydiv {
    padding-top: 125px;
  }
  .service-card {
    background-image: none;
    display: flex;
    position: relative;
    align-content: center;
    justify-content: center;
  }
  .service-p{
    text-align: justify;
  }
}

@media screen and (max-width: 650px) {
  .icon {
    display: none;
  }
  .mydiv {
    padding-top: 120px;
  }
  .service-p {
    padding: 5%;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: justify;
  }
  .service-card {
    background-image: none;
    display: flex;
    position: relative;
    align-content: center;
    justify-content: center;
    margin: 0%;
  }
  .ad-service-1,
.ad-service-2,
.ad-service-3,
.ad-service-4,
.ad-service-5,
.ad-service-6 {
    flex-direction: column;
    width: 85%;
  }

  .ad-img-1,
  .ad-img-2,
  .ad-img-3,
  .ad-img-4,
  .ad-img-5,
  .ad-img-6 {
    width: 100%;
    padding: 0%;
  }

  .ad-img-1 img {
    margin-top: 10px;
  }

  .ad-img-2 img {
    margin-top: 10px;
  }

  .ad-img-3 img {
    margin-top: 10px;
  }

  .ad-img-4 img {
    margin-top: 10px;
  }

  .ad-img-5 img {
    margin-top: 10px;
  }

  .ad-img-6 img {
    margin-top: 10px;
  }
  .ad-content-1,
.ad-content-2,
.ad-content-3,
.ad-content-4,
.ad-content-5,
.ad-content-6 {
  width: 100%;
    padding: 0%;
    text-align: left;
}
}
